// Corrige largura de links ao utilizar menu lateral
section.content {
    .nav {
        .nav-item {
            .nav-link {
                width: auto !important;
            }
        }
    }
}
