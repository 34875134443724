// Variáveis
$breakpoints: (
    xxl: 1px,
    xl: 1280px,
    lg: 992px,
    md: 768px,
    sm: 576px
);

$rem-width: (
    1: 1rem,
    2: 2rem,
    3: 3rem,
    4: 4rem,
    5: 5rem,
    10: 10rem,
    11: 11rem,
    12: 12rem,
    13: 13rem,
    14: 14rem,
    15: 15rem,
    20: 20rem,
    25: 25rem
);

// Helpers
// Texto
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@for $i from 1 through 10 {
    .text-#{$i}-overflow {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: $i;
        -webkit-box-orient: vertical;
    }
}

// Largura
@each $prefix, $pixels in $breakpoints {
    $width: if($pixels != 1px, max-width, min-width);

    @media screen and ($width: #{$pixels}) {
        @each $id, $value in $rem-width {
            .w-#{$prefix}-#{$id}-rem {
                width: #{$value};
            }
        }
    }
}

// Altura máxima
@each $prefix, $pixels in $breakpoints {
    $width: if($pixels != 1px, max-width, min-width);

    @media screen and ($width: #{$pixels}) {
        @each $id, $value in $rem-width {
            .mh-#{$prefix}-#{$id}-rem {
                max-height: #{$value};
            }
        }
    }
}
