.notification-dropdown {
    width: 300px;
    right: 0;
    margin-right: -150px;
    position: absolute;
    z-index: 1000;
    padding: 0.5rem 0;
    font-size: 1rem;
    top: 100%;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 18%);

    @include media-breakpoint-down(sm) {
        right: -50%;
        margin-right: 0;
    }
}

.notifications-wrapper {
    max-height: 32.5rem;
    overflow-y: auto;

    @include media-breakpoint-down(sm) {
        max-height: 72.5vh;
    }
}

.notification-loading {
    position: absolute;
    bottom: 0;
    padding: 5rem 1rem 1.5rem;
    width: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
