//
// Component: Main Sidebar
//

.main-sidebar {
    .brand-text {
        .logo {
            width: 150px;
            height: 105px;
            object-fit: contain;
        }
    }

    &:hover {
        .brand-text {
            img {
                margin-right: -0.35rem;
            }
        }
    }
}
// Sidebar navigation menu
.nav-sidebar {
    .nav-link p {
        margin-bottom: -5px;
    }
}
